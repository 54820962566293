<template>
  <div>
    <!-- <dashboard-navbar :routes="routes"></dashboard-navbar> -->
    <div class="content" @click="$sidebar.displaySidebar(false)">
      <base-header class="pt-3 mb-4">
        <section
          class="d-flex justify-content-center align-items-center flex-column py-3 section-heading"
        >
          <div class="wrapper-photo">
            <div class="photo-student">
              <img
                class="photo"
                :src="`${getInfoAuth.image}?${new Date().getTime()}`"
                alt="photo user"
              />
            </div>
          </div>
          <div class="wrapper-text">
            <h2 class="name-heading">Welcome, {{ getInfoAuth.name }}</h2>
            <span class="date-heading">{{ getInfoDate }}</span>
          </div>
        </section>
      </base-header>
      <div class="container-fluid mt-2">
        <div class="card-deck flex-column flex-md-row">
          <card>
            <div class="heading-chart">
              <h3>New Student</h3>
              <div class="select-row">
                <el-select
                  class="select-primary pagination-select"
                  size="mini"
                  v-model="optionValue"
                  placeholder="Per page"
                >
                  <el-option
                    class="select-primary"
                    v-for="item in option.weekOptions"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </div>
            </div>
            <Area :series="series" :titleY="student" />
          </card>
          <div class="wrapper-right">
            <card>
              <div class="heading-recently">
                <h3>Recently Joined</h3>
                <div class="d-flex align-items-center">
                  <span>{{ students.recently.recently_joined }}</span>
                  <svg class="custom-icon arrow text-success">
                    <use href="#arrow-up" />
                  </svg>
                </div>
              </div>
              <div v-if="students.recently.users.length !== 0" class="body-recently">
                <div
                  v-for="student in students.recently.users"
                  :key="student.id"
                  class="wrapper-account"
                >
                  <div class="avatar rounded-circle">
                    <img :alt="student.name" :src="student.photo" />
                  </div>
                  <p :title="student.name">{{ student.name }}</p>
                </div>
              </div>
              <div v-else class="body-recently empty-recently">
                <span>Data Empty</span>
              </div>
            </card>
            <card>
              <!-- <h3>Total Student</h3> -->
              <div class="total-student">
                <svg class="custom-icon">
                  <use href="#students" />
                </svg>
                <div>
                  <p>{{ students.total }}</p>
                  <p class="desc">Student</p>
                </div>
              </div>
            </card>
          </div>
        </div>
      </div>
      <div class="container-fluid mt-2">
        <div class="card-deck flex-column flex-md-row">
          <TopCourse
            :title="courses.topCourse.title"
            :subTitle="courses.topCourse.subtitle"
            :list="courses.topCourse.list"
            describe="Student"
          />
          <TopCourse
            :title="courses.topCoursePurchased.title"
            :subTitle="courses.topCoursePurchased.subtitle"
            :list="courses.topCoursePurchased.list"
            describe="Course"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import DashboardNavbar from '@/Layout/DashboardNavbar.vue';
import { Select, Option } from 'element-ui'
import { mapGetters } from 'vuex'
// import { dataChart } from './dummy'
import { getTop5AndAllMember, getRecentlyJoin, getnewStudent } from '@/services/admin'
import Area from '@/components/Highchart/Area.vue'
import TopCourse from '@/components/Dashboard/TopCourse.vue'
export default {
  name: "Dashboard",
  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    Area,
    TopCourse
    // DashboardNavbar,
  },
  data() {
    return {
      routes: [
        {
          name: "ListStudents",
          alias: "List Students"
        }
      ],
      series: [{
        type: 'area',
        name: 'Students',
        data: []
      }],
      option: {
        // value: '1 WEEK',
        weekOptions: [
          {
            name: 'Last Week',
            value: '1 WEEK'
          },
          {
            name: 'Last Month',
            value: '1 MONTH'
          },
          {
            name: 'Last 3 Month',
            value: '3 MONTH'
          },
        ]
      },
      optionValue: '1 WEEK',
      courses: {
        topCourse: {
          title: {
            title: 'Top 5 Courses',
            subtitle: 'Being Learned'
          },
          subtitle: {
            leftTitle: 'Course',
            rightTitle: 'Total Participant',
          },
          list: []
        },
        topCoursePurchased:
        {
          title: {
            title: 'Top 5 Purchased Courses',
            subtitle: 'Last Week'
          },
          subtitle: {
            leftTitle: 'Course',
            rightTitle: 'Total Purchased',
          },
          list: []
        },
      },
      students: {
        recently: {
          users: []
        },
        total: 0
      },
    }
  },
  watch: {
    async optionValue(val) {
      try {
        const { data: dataChart } = await getnewStudent(this.optionValue)
        dataChart.forEach(function (point) {
          point[0] = Date.parse(point[0])
        });
        this.series[0].data = dataChart
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    }
  },
  computed: {
    ...mapGetters("auth", ["getInfoAuth"]),
    getInfoDate() {
      const today = new Date();
      return new Intl.DateTimeFormat('en-GB', { dateStyle: 'full', }).format(today)
    },
  },
  methods: {
    async getTopCourse() {
      try {
        const { data: { course: dataTopCourse = [], purchase: dataTopPurchased = [], member: dataMember = 0 } = {} } = await getTop5AndAllMember()
        this.courses.topCourse.list = dataTopCourse
        this.courses.topCoursePurchased.list = dataTopPurchased
        this.students.total = dataMember
        const { data: dataRecenltyJoin } = await getRecentlyJoin()
        this.students.recently = dataRecenltyJoin
        const { data: dataChart } = await getnewStudent(this.optionValue)

        dataChart.forEach(function (point) {
          point[0] = Date.parse(point[0])
        });

        this.series[0].data = dataChart
      } catch (error) {
        if (error.code === 401) {
          this.$cookies.remove('CERT');
          this.$store.dispatch('auth/removeAuth');
          this.$router.replace({ name: "Login" })
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error.msg
          });
        } else {
          this.$notify({
            type: "danger",
            verticalAlign: "top",
            horizontalAlign: "center",
            closeOnClick: true,
            message: error
          });
        }
      }
    }
  },
  mounted() {
    this.getTopCourse()
  }

}
</script>
<style lang="scss" scoped>
@import "@/assets/sass/argon.scss";
.section-heading {
  text-align: center;

  .wrapper-photo {
    display: flex;
    height: 120px;
    align-items: center;
    justify-content: center;

    .photo-student {
      height: 100px;
      width: 100px;
      border-radius: 100px;
      box-shadow: 0 7px 4px rgba(0, 0, 0, 0.3);
      overflow: hidden;

      .photo {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
  }

  .wrapper-text {
    line-height: 0.2;

    .name-heading {
      font-size: 42px;
      font-weight: 500;
    }

    .date-heading {
      font-size: 20px;
      color: $sub-gray;
      margin-bottom: 20px;
    }
  }
}
.heading-chart {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  h3 {
    font-size: 24px;
    font-weight: 500;
  }
}
.wrapper-right {
  min-width: 360px;

  h3 {
    font-weight: 500;
    font-size: 24px;
    margin-bottom: 0px;
  }
  .heading-recently {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    span {
      color: $sub-gray;
      font-size: 18px;
    }
  }
  .body-recently {
    display: flex;
    min-height: 80px;
    // justify-content: space-between;
    align-items: center;

    &.empty-recently {
      justify-content: center;
    }
    .wrapper-account {
      text-align: center;
      line-height: 0;
      max-width: 50px;
      width: 100%;
      &:not(:last-child) {
        margin-right: 10px;
      }
      p {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font-size: 12px;
      }
    }
  }

  .total-student {
    min-height: 110px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;

    p {
      margin: 0;
      font-weight: 400;
      font-size: 28px;
      text-align: center;
      line-height: 1;

      .desc {
        font-size: 32px;
      }
    }

    .custom-icon {
      width: 80px;
      height: 80px;
      margin-right: 50px;
      color: $blue-ar-vr;
    }
  }
}

.custom-icon {
  display: inline-block;
  width: 15px;
  height: 15px;
  &.star {
    margin-right: 2px;
  }
  &.arrow {
    width: 20px;
    height: 20px;
  }
}
</style>