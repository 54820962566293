<template>
  <highcharts class="hc" :options="chartOptions"></highcharts>
</template>
<script>
export default {
  name: "Area",
  props: ["title", "height", "legend", "series", "categories"],
  // title: {
  //   type: String,
  //   default: null
  // },
  // height: {
  //   type: Number,
  //   default: 285
  // },
  // legend: {
  //   type: Boolean,
  //   default: false
  // },
  // series: {
  //   type: Array,
  //   required: true
  // },
  // categories: {
  //   type: Array,
  //   required: true
  // }
  // },
  computed: {
    chartOptions() {
      return {
        chart: {
          type: 'column',
          height: this.height,
        },
        title: {
          text: this.title
        },
        legend: {
          enabled: this.legend
        },
        xAxis: {
          categories: this.categories,
          crosshair: true
        },
        yAxis: {
          min: 0,
          title: {
            text: 'minutes (m)'
          }
        },
        credits: {
          enabled: false,
        },
        plotOptions: {
          column: {
            pointPadding: 0.2,
            borderWidth: 0
          }
        },
        tooltip: {
          headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
          pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
            '<td style="padding:0"><b>{point.y:.1f} m</b></td></tr>',
          footerFormat: '</table>',
          shared: true,
          useHTML: true
        },
        series: this.series
      }
    }
  }
}
</script>