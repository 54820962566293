import { api } from "./axios";

const getAllMember = () => {
  return api.get("v1/member");
};
const getDetailMember = id => {
  return api.get(`v1/member/${id}`);
};
const ActiveOrNonActive = data => {
  return api.put(`v1/member`, data);
};
const getMyCourse = id => {
  return api.get(`v1/member/course/${id}`);
};

const deletePhoto = id => {
  return api.delete(`v1/member/photo?id=${id}`);
};
const summaryVisiting = data => {
  return api.post(`v1/visiting/summary`, data);
};

export {
  getAllMember,
  getDetailMember,
  ActiveOrNonActive,
  getMyCourse,
  deletePhoto,
  summaryVisiting
};
